<template>
    <div>
        <b-modal v-model="typeModal" centered hide-footer hide-header size="lg">
            <div class="row text-center">
                <div class="col-sm-12">
                    <h3>Gestion des types</h3>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-12">
                    <b-list-group>
                        <b-list-group-item
                            ><div class="row">
                                <div class="col-sm-8">
                                    <b-form-input
                                        v-model="addTypeLabel"
                                        placeholder="Nom du type d'hébergement (exemple: Hôtel)"
                                    ></b-form-input>
                                </div>
                                <div class="col-sm-4">
                                    <b-button @click="addType"
                                        >Ajouter</b-button
                                    >
                                </div>
                            </div></b-list-group-item
                        >
                        <b-list-group-item v-for="type in types" :key="type.id"
                            ><div class="row">
                                <div class="col-sm-6">
                                    <b>{{ type.label }}</b>
                                </div>
                                <div class="col-sm-2">
                                    <b-button
                                        variant="outline-danger"
                                        size="sm"
                                        @click="deleteType(type.id)"
                                        >Supprimer</b-button
                                    >
                                </div>
                            </div></b-list-group-item
                        >
                    </b-list-group>
                </div>
            </div>
            <br />
            <div class="row text-center">
                <div class="col-sm-12">
                    <b-button @click="openTypeModal">Fermer</b-button>
                </div>
            </div>
        </b-modal>
        <div class="row">
            <div class="col-sm-12">
                <h3>Mes hébergements</h3>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-2">
                <b-button
                    v-if="openAdd == false && openEdit == false"
                    @click="openAddMenu"
                    >Ajouter une fiche</b-button
                >
                <b-button
                    v-if="openAdd == true"
                    variant="outline-success"
                    @click="addFiche"
                    >Ajouter une fiche</b-button
                >
                <b-button v-if="openEdit == true" @click="editFiche"
                    >Modifier la fiche</b-button
                >
            </div>

            <div class="col-sm-6">
                <b-form-input
                    v-model="searchFiche"
                    placeholder="Rechercher une fiche"
                ></b-form-input>
            </div>
            <div class="col-sm-3">
                <b-button @click="openTypeModal"
                    >Gérer les types d'hébergements</b-button
                >
            </div>
        </div>
        <br />
        <div v-if="openAdd || openEdit" class="row">
            <div class="col-sm-3">
                <b-form-input
                    v-model="createFiche.name"
                    placeholder="Nom"
                ></b-form-input>
            </div>
            <div class="col-sm-3">
                <b-form-input
                    v-model="createFiche.originalId"
                    placeholder="Identifiant SIT"
                ></b-form-input>
            </div>
            <div class="col-sm-3">
                <b-form-input
                    v-model="createFiche.adress"
                    placeholder="Adresse"
                ></b-form-input>
            </div>
            <div class="col-sm-3">
                <b-form-input
                    v-model="createFiche.phone"
                    placeholder="Téléphone"
                ></b-form-input>
            </div>
        </div>
        <br />
        <div v-if="openAdd || openEdit" class="row">
            <div class="col-sm-3">
                <b-form-select
                    v-model="createFiche.TypeId"
                    :options="selectTypeOptions"
                ></b-form-select>
            </div>
            <div class="col-sm-3">
                <b-form-input
                    v-model="createFiche.mail"
                    placeholder="Email"
                ></b-form-input>
            </div>
            <div class="col-sm-2">
                <b-form-input
                    v-model="createFiche.ranking"
                    placeholder="Classement"
                ></b-form-input>
            </div>
            <div class="col-sm-2">
                <b-form-input
                    v-model="createFiche.prices"
                    placeholder="Tarifs"
                ></b-form-input>
            </div>
            <div class="col-sm-2">
                <b-button
                    v-if="openAdd == true && openEdit == false"
                    @click="openAddMenu"
                    >Annuler</b-button
                >
                <b-button
                    v-if="openAdd == false && openEdit == true"
                    @click="openEditFiche"
                    >Annuler</b-button
                >
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-12">
                <table class="table table-fixed">
                    <thead class="thead">
                        <tr>
                            <th>Nom</th>
                            <th>Original Id</th>
                            <th>Adresse</th>
                            <th>Téléphone</th>
                            <th>Type</th>
                            <th>Email</th>
                            <th>Classement</th>
                            <th>Tarifs</th>
                            <th>Gérer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="fiche in fiches" :key="fiche.id">
                            <td>{{ fiche.name }}</td>
                            <td>{{ fiche.originalId }}</td>
                            <td>{{ fiche.adress }}</td>
                            <td>{{ fiche.phone }}</td>
                            <span v-for="type in types" :key="type.id">
                                <td v-if="type.id == fiche.TypeId">
                                    {{ type.label }}
                                </td>
                            </span>
                            <td>{{ fiche.mail }}</td>
                            <td>{{ fiche.ranking }}</td>
                            <td>{{ fiche.prices }}</td>
                            <td>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <b-button
                                            size="sm"
                                            variant="outline-success"
                                            @click="openEditFiche(fiche)"
                                            >Modifier</b-button
                                        >
                                    </div>
                                    <div class="col-sm-6">
                                        <b-button
                                            size="sm"
                                            variant="outline-danger"
                                            @click="deleteFiche(fiche.id)"
                                            >Supprimer</b-button
                                        >
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 d-flex justify-content-center">
                <ul class="pagination">
                    <li class="page-item" @click="changePage('previous')">
                        <a class="page-link">Précédent</a>
                    </li>
                    <li
                        v-for="page in quotient"
                        :key="page"
                        class="page-item"
                        @click="changePage(page)"
                    >
                        <a class="page-link"> {{ page }}</a>
                    </li>
                    <li class="page-item" @click="changePage('next')">
                        <a class="page-link">Suivant</a>
                    </li>
                </ul>
            </div>
        </div>
        <br />
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            fiches: [],
            pageCounter: 0,
            quotient: 0,
            currentPage: 1,
            searchFiche: "",
            openAdd: false,
            openEdit: false,
            createFiche: {},
            types: [],
            typeModal: false,
            addTypeLabel: "",
            selectTypeOptions: [],
        };
    },
    watch: {
        searchFiche: async function() {
            this.currentPage = 1;
            if (this.searchFiche != "") {
                this.fiches = await this.$store.dispatch(
                    "dispo/getAllProjectFichesFiltered",
                    {
                        projectId: 6,
                        filter: this.searchFiche,
                    },
                );
            } else {
                this.fiches = await this.$store.dispatch(
                    "dispo/getAllProjectFiches",
                    {
                        projectId: 6,

                        limit: 6,
                    },
                );
            }
        },
        types: function() {
            this.selectTypeOptions = [];
            this.types.forEach(type => {
                this.selectTypeOptions.push({
                    text: type.label,
                    value: type.id,
                });
            });
        },
    },
    async beforeMount() {
        this.fiches = await this.$store.dispatch("dispo/getAllProjectFiches", {
            projectId: 6,
        });
        // eslint-disable-next-line no-unused-vars
        for (const fiche of this.fiches) {
            this.pageCounter = ++this.pageCounter;
        }
        this.quotient = Math.floor(this.pageCounter / 6) + 1;
        this.fiches = await this.$store.dispatch("dispo/getAllProjectFiches", {
            projectId: 6,
            limit: 6,
        });
        this.types = await this.$store.dispatch("dispo/getAllTypes", {
            projectId: 6,
        });
    },
    methods: {
        changePage: async function(page) {
            if (page == "previous") {
                if (this.currentPage - 1 <= 1) {
                    this.fiches = await this.$store.dispatch(
                        "dispo/getAllProjectFiches",
                        {
                            projectId: 6,
                            limit: 6,
                        },
                    );
                    this.currentPage = this.currentPage - 1;
                } else {
                    this.currentPage = this.currentPage - 1;
                    this.fiches = await this.$store.dispatch(
                        "dispo/getAllProjectFiches",
                        {
                            projectId: 6,
                            page: this.currentPage * 6 - 6,
                            limit: 6,
                        },
                    );
                }
            } else if (page == "next") {
                this.fiches = await this.$store.dispatch(
                    "dispo/getAllProjectFiches",
                    {
                        projectId: 6,
                        page: this.currentPage * 6,
                        limit: 6,
                    },
                );
                this.currentPage = this.currentPage + 1;
            } else {
                if (page == 1) {
                    this.fiches = await this.$store.dispatch(
                        "dispo/getAllProjectFiches",
                        {
                            projectId: 6,
                            limit: 6,
                        },
                    );
                    this.currentPage = page;
                } else {
                    this.fiches = await this.$store.dispatch(
                        "dispo/getAllProjectFiches",
                        {
                            projectId: 6,
                            page: page * 6 - 6,
                            limit: 6,
                        },
                    );
                    this.currentPage = page;
                }
            }
        },
        openAddMenu: async function() {
            this.openAdd = !this.openAdd;
        },

        addFiche: async function() {
            await this.$store.dispatch("dispo/createFiche", {
                projectId: 6,
                name: this.createFiche.name,
                originalId: this.createFiche.originalId,
                adress: this.createFiche.adress,
                phone: this.createFiche.phone,
                typeId: this.createFiche.TypeId,
                mail: this.createFiche.mail,
                ranking: this.createFiche.ranking,
                prices: this.createFiche.prices,
            });
            this.createFiche = {};
            this.fiches = await this.$store.dispatch(
                "dispo/getAllProjectFiches",
                {
                    projectId: 6,
                },
            );

            this.pageCounter = 0;
            this.quotient = 0;
            this.currentPage = 1;
            // eslint-disable-next-line no-unused-vars
            for (const fiche of this.fiches) {
                this.pageCounter = ++this.pageCounter;
            }
            this.quotient = Math.floor(this.pageCounter / 6) + 1;
            this.fiches = await this.$store.dispatch(
                "dispo/getAllProjectFiches",
                {
                    projectId: 6,
                    limit: 6,
                },
            );
        },
        openTypeModal: function() {
            this.typeModal = !this.typeModal;
        },
        addType: async function() {
            await this.$store.dispatch("dispo/createType", {
                projectId: 6,
                label: this.addTypeLabel,
            });
            this.types = await this.$store.dispatch("dispo/getAllTypes", {
                projectId: 6,
            });
            this.addTypeLabel = "";
        },
        deleteType: async function(id) {
            await this.$store.dispatch("dispo/deleteType", {
                projectId: 6,
                typeId: id,
            });
            this.types = await this.$store.dispatch("dispo/getAllTypes", {
                projectId: 6,
            });
        },
        deleteFiche: async function(id) {
            await this.$store.dispatch("dispo/deleteFiche", {
                projectId: 6,
                ficheId: id,
            });
            this.fiches = await this.$store.dispatch(
                "dispo/getAllProjectFiches",
                {
                    projectId: 6,
                },
            );
            this.pageCounter = 0;
            this.quotient = 0;
            this.currentPage = 1;

            // eslint-disable-next-line no-unused-vars
            for (const fiche of this.fiches) {
                this.pageCounter = ++this.pageCounter;
            }
            this.quotient = Math.floor(this.pageCounter / 6) + 1;
            this.fiches = await this.$store.dispatch(
                "dispo/getAllProjectFiches",
                {
                    projectId: 6,
                    limit: 6,
                },
            );
        },
        openEditFiche: function(fiche) {
            this.openEdit = !this.openEdit;
            this.openAdd = false;
            if (this.openEdit == true) {
                this.createFiche = fiche;
            } else {
                this.createFiche = {};
            }
        },
        editFiche: async function() {
            await this.$store.dispatch("dispo/updateFiche", {
                projectId: 6,
                ficheId: this.createFiche.id,
                name: this.createFiche.name,
                originalId: this.createFiche.originalId,
                adress: this.createFiche.adress,
                phone: this.createFiche.phone,
                typeId: this.createFiche.TypeId,
                mail: this.createFiche.mail,
                ranking: this.createFiche.ranking,
                prices: this.createFiche.prices,
            });
        },
    },
};
</script>
<style scoped>
.pagination > li > a {
    background-color: #444444;
    color: white;
    cursor: pointer;
}
.pagination > li > a:hover {
    background-color: white;
    color: #444444;
    cursor: pointer;
}
.pagination > li > a:active {
    background-color: white;
    color: #444444;
    cursor: pointer;
}
.thead {
    background-color: #444444;
    color: white;
}
</style>
