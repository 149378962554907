<template>
    <div class="d-md-flex h-md-100 align-items-center">
        <div class="col-md-2 p-0 bg-indigo h-md-100">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <b-list-group>
                        <b-list-group-item
                            :class="{
                                'list-menu': menuStyle[0]['list-menu'],
                                'list-menu-active':
                                    menuStyle[0]['list-menu-active'],
                            }"
                            @click="goMenu(0)"
                        >
                            <h5>Gérer les hébergements</h5>
                        </b-list-group-item>
                        <b-list-group-item
                            :class="{
                                'list-menu': menuStyle[1]['list-menu'],
                                'list-menu-active':
                                    menuStyle[1]['list-menu-active'],
                            }"
                            @click="goMenu(1)"
                        >
                            <h5>Gérer les dispos</h5>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </div>
            <br />
            <br />
            <div v-if="menuSelect == 1" class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <h5 class="text-white">Hébérgements</h5>
                </div>
                <div class="col-sm-5">
                    <b-form-input
                        v-model="searchFiche"
                        size="sm"
                    ></b-form-input>
                </div>
            </div>
            <br />
            <br />
            <div v-if="menuSelect == 1" class="row">
                <div class="col-sm-12 text-center">
                    <b-list-group>
                        <div v-for="item in listFiche" :key="item.id">
                            <b-list-group-item
                                v-if="item.id != ficheSelect"
                                class="list"
                                button
                                @click="setFiche(item.id)"
                                ><h6>{{ item.name }}</h6></b-list-group-item
                            >
                            <b-list-group-item
                                v-if="item.id == ficheSelect"
                                class="list-active"
                                button
                                @click="setFiche(item.id)"
                                ><h6>{{ item.name }}</h6></b-list-group-item
                            >
                        </div></b-list-group
                    >
                </div>
            </div>
        </div>
        <div class="col-md-10 p-0 h-md-100 loginarea">
            <div class="d-md-flex h-md-100 p-5">
                <GererHebergement v-if="menuSelect == 0" />
                <GererDispo
                    v-if="menuSelect == 1"
                    :fiche-dispo="ficheDispo"
                    :fiche-select="ficheSelect"
                />
                <VoirDispo v-if="menuSelect == 2" />
            </div>
        </div>
    </div>
</template>
<script>
import GererDispo from "@/components/common/dispo/component/GererDispo";
import GererHebergement from "@/components/common/dispo/component/GererHebergement";
import Vue from "vue";
export default {
    components: {
        GererDispo,
        GererHebergement,
    },
    data: function() {
        return {
            menuModal: false,
            projects: [],
            fiches: [],
            fichesOption: [],
            ficheSelect: "",
            ficheDispo: [],
            searchFiche: "",
            listFiche: "",
            menuStyle: [
                {
                    "list-menu": true,
                    "list-menu-active": false,
                },
                {
                    "list-menu": false,
                    "list-menu-active": true,
                },
                {
                    "list-menu": true,
                    "list-menu-active": false,
                },
            ],
            menuSelect: 1,
        };
    },
    watch: {
        ficheSelect: async function() {
            this.ficheDispo = await this.$store.dispatch(
                "dispo/getFicheDispos",
                { ficheId: this.ficheSelect },
            );
        },
        searchFiche: function() {
            this.researchFiche();
        },
        context: async function() {
            this.dispo = {};
            this.dispoSelect = {};
            if (this.daySelect != "" && this.testDayValue() == true) {
                let daySelect = this.daySelect + "T00:00:00Z";
                this.dispo = await this.$store.dispatch("dispo/getDispo", {
                    ficheId: this.ficheSelect,
                    date: daySelect,
                });

                this.dispoSelect = this.dispo.dispo;
            }
        },
    },
    async beforeMount() {
        this.fiches = await this.$store.dispatch("dispo/getAllProjectFiches", {
            projectId: 6,
        });
        this.listFiche = this.fiches;
        for (const fiche of this.fiches) {
            this.fichesOption.push({
                value: fiche.id,
                text: fiche.name,
            });
        }
    },
    methods: {
        setFiche: function(ficheId) {
            this.ficheSelect = ficheId;
        },
        openModal: function() {
            this.menuModal = !this.menuModal;
        },

        researchFiche: function() {
            if (this.searchFiche != "") {
                this.listFiche = [];
                this.fiches.forEach(fiche => {
                    if (
                        fiche.name
                            .toUpperCase()
                            .indexOf(this.searchFiche.toUpperCase()) > -1
                    ) {
                        this.listFiche.push(fiche);
                    }
                });
            } else {
                this.listFiche = this.fiches;
            }
        },
        goMenu: async function(menuIndex) {
            this.menuStyle.forEach((menu, index) => {
                if (index == menuIndex) {
                    Vue.set(this.menuStyle[index], "list-menu", false);
                    Vue.set(this.menuStyle[index], "list-menu-active", true);
                    this.menuSelect = index;
                } else {
                    Vue.set(this.menuStyle[index], "list-menu", true);
                    Vue.set(this.menuStyle[index], "list-menu-active", false);
                }
            });
        },
    },
};
</script>
<style scoped>
@media (min-width: 768px) {
    .h-md-100 {
        height: 100vh;
    }
}
.small {
    visibility: hidden;
}
.bg-indigo {
    background: #444444;
}
.list {
    color: white;
    background-color: #444444;
}
.list:hover {
    color: #444444;
    background-color: #198a37;
    cursor: pointer;
}
.list-active {
    color: #444444;
    background-color: #198a37;
}
.list-menu {
    color: white;
    background-color: #444444;
}
.list-menu:hover {
    color: white;
    background-color: #292929;
    cursor: pointer;
}
.list-menu-active {
    color: white;
    background-color: #292929;
}
.overflow-list {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.list-group {
    max-height: 600px;
    scrollbar-color: #292929 #444444;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.list-group::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

.list-group::-webkit-scrollbar-track {
    background: #444444; /* color of the tracking area */
}

.list-group::-webkit-scrollbar-thumb {
    background-color: #292929; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #444444; /* creates padding around scroll thumb */
}
</style>
