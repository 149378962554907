<template>
    <div class="col-sm-12">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12">
                    <b-button @click="goViewDispo">Voir dispos</b-button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-12">
                    <b-calendar
                        v-model="daySelect"
                        block
                        locale="fr-FR"
                        label-help=""
                        :date-info-fn="dateClass"
                        @context="onContext"
                    >
                    </b-calendar>
                </div>
            </div>

            <br />
            <div v-if="testDayValue() == true" class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        <b-card>
                            <b-card-title>{{
                                context.activeFormatted
                            }}</b-card-title>
                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-select
                                        v-model="dispoSelect"
                                        :options="dispoOption"
                                    ></b-form-select>
                                </div>
                                <div
                                    v-if="dispoSelect != 'Complet'"
                                    class="col-sm-6"
                                >
                                    <b-form-textarea
                                        v-model="dispo.description"
                                        rows="4"
                                        placeholder="Exemple: Nombres de chambres"
                                    ></b-form-textarea>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-sm-12">
                                    <b-button
                                        class="button-color"
                                        @click="updateDispo"
                                        >Sauvergarder
                                        <b-icon icon="download"></b-icon
                                    ></b-button>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div
                    v-if="
                        testDayValue() == false &&
                            daySelect &&
                            ficheSelect != ''
                    "
                    class="row"
                >
                    <div class="col-sm-12">
                        <b-card>
                            <b-card-title>{{
                                context.activeFormatted
                            }}</b-card-title>
                            <div class="row">
                                <div class="col-sm-12">
                                    <p>
                                        Disponibilité non renseigné pour ce
                                        projet
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-sm-6">
                                    <b-form-select
                                        v-model="dispoSelect"
                                        :options="dispoOption"
                                    ></b-form-select>
                                </div>

                                <div
                                    v-if="dispoSelect != 'Complet'"
                                    class="col-sm-6"
                                >
                                    <b-form-textarea
                                        v-model="dispo.description"
                                        rows="4"
                                        placeholder="Exemple: Nombres de chambres"
                                    ></b-form-textarea>
                                </div>
                            </div>

                            <br />
                            <div class="row">
                                <div class="col-sm-12">
                                    <b-button
                                        class="button-color"
                                        @click="addDispo"
                                        >Ajouter cette Disponibilité
                                        <b-icon icon="download"></b-icon
                                    ></b-button>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["ficheDispo", "ficheSelect"],
    data: function() {
        return {
            daySelect: "",
            context: null,
            listFiche: "",
            dispoOption: [
                {
                    value: "Complet",
                    text: "Complet",
                },
                { value: "Disponible", text: "Disponible" },
            ],
            dispoSelect: {},
            dispo: {},
        };
    },
    watch: {
        context: async function() {
            this.dispo = {};
            this.dispoSelect = {};
            if (this.daySelect != "" && this.testDayValue() == true) {
                let daySelect = this.daySelect + "T00:00:00Z";
                this.dispo = await this.$store.dispatch("dispo/getDispo", {
                    ficheId: this.ficheSelect,
                    date: daySelect,
                });

                this.dispoSelect = this.dispo.dispo;
            }
        },
    },
    methods: {
        dateClass: function(ymd, date) {
            let flag;
            this.ficheDispo.forEach(dateDispo => {
                let d = new Date(dateDispo.date);

                if (
                    d.getDate() == date.getDate() &&
                    d.getMonth() == date.getMonth() &&
                    d.getFullYear() == date.getFullYear()
                ) {
                    if (dateDispo.dispo == "Complet") {
                        flag = 1;
                    } else if (dateDispo.dispo == "Disponible") {
                        flag = 0;
                    }
                }
            });
            if (flag == 1) {
                return "table-danger";
            } else if (flag == 0) {
                return "table-success";
            }
        },
        testDayValue: function() {
            let flag = false;
            this.ficheDispo.forEach(dateDispo => {
                let d = new Date(dateDispo.date);
                let date = new Date(this.daySelect);

                if (
                    d.getDate() == date.getDate() &&
                    d.getMonth() == date.getMonth() &&
                    d.getFullYear() == date.getFullYear()
                ) {
                    flag = true;
                }
            });
            return flag;
        },
        onContext(ctx) {
            this.context = ctx;
        },

        addDispo: async function() {
            let daySelect = this.daySelect + "T00:00:00Z";
            await this.$store.dispatch("dispo/createDispo", {
                ficheId: this.ficheSelect,
                date: daySelect,
                dispo: this.dispoSelect,
                description: this.dispo.description,
            });
            this.refreshDispo();
            this.refreshDispo();
        },
        updateDispo: async function() {
            let daySelect = this.daySelect + "T00:00:00Z";
            await this.$store.dispatch("dispo/updateDispo", {
                ficheId: this.ficheSelect,
                date: daySelect,
                dispo: this.dispoSelect,
                description: this.dispo.description,
            });
            this.refreshDispo();
            this.refreshDispo();
        },
        refreshDispo: async function() {
            this.ficheDispo = await this.$store.dispatch(
                "dispo/getFicheDispos",
                { ficheId: this.ficheSelect },
            );
            this.dateClass();
        },
        goViewDispo: function() {
            this.$router.push({
                name: "SeeDispo",
                params: {
                    projectId: 6,
                },
            });
        },
    },
};
</script>
<style>
.button-color {
    background-color: #198a37;
    color: white;
    border: 2px solid #198a37;
}
.button-color:hover {
    background-color: white;
    color: #198a37;
    border: 2px solid #198a37;
}
</style>
